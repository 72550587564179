import React, { useState, useEffect } from 'react';
import styles from './Subscription.module.scss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface SubscriptionProps {
  subscriptionType: string;
  onSubscriptionChange: (type: string) => void;
  membersCount: number;
}

export const Subscription: React.FC<SubscriptionProps> = ({
  membersCount,
  onSubscriptionChange,
}) => {
  // State to manage selected subscription
  const [selectedSubscription, setSelectedSubscription] =
    useState<string>('male');

  // Effect to update subscription type based on membersCount
  useEffect(() => {
    if (membersCount > 5) {
      setSelectedSubscription('FIXED'); // Automatically switch to fixed subscription
    } else {
      setSelectedSubscription('FREE'); // Keep free subscription if 5 or fewer members
    }
  }, [membersCount]);

  // Handle change of subscription
  const handleSubscriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedSubscription(event.target.value);
    onSubscriptionChange(event.target.value); // Notify parent component
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Payment options</div>
      </div>
      <div>
        <div className={styles.subTitle}>Free circles:</div>
        <div className={styles.description}>
          Circles with up to five members are free to create and join.
        </div>
      </div>
      <div>
        <div className={styles.subTitle}>
          Payments option for Larger Circles:
        </div>
        <div className={styles.description}>
          <div className={styles.subTitle}> Fixed subscription</div>
          <ul>
            <li>
              Ideal for organizations like corporations, schools, and other
              structured groups.
            </li>
            <li>Cost 10$ per month per Member. Cancel any time</li>
            <li>Payment is charged immediately upon Circle creation</li>
          </ul>
        </div>
      </div>
      <div>
        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className={styles.subTitle}
          >
            Choose:
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedSubscription} // Bind state to RadioGroup
            name="radio-buttons-group"
            onChange={handleSubscriptionChange} // Handle changes
          >
            <FormControlLabel
              value="FREE"
              control={<Radio />}
              label="Free subscription"
              className={styles.description}
              disabled={membersCount > 5} // Disable free subscription if membersCount > 5
            />
            <FormControlLabel
              value="FIXED"
              control={<Radio />}
              label="Fixed subscription"
              className={styles.description}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};
