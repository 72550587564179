import api from './baseApi';

export const circleApi = {
  createCircle: (circleNewData: any) => {
    return api.post(`tribes/`, circleNewData);
  },

  getCircle: (id: string) => {
    return api.get(`tribes/${id}`);
  },

  getVideoId: (id: string) => {
    return api.get(`files/${id}`);
  },
};
