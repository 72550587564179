import React, { useState } from 'react';
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiTelInput } from 'mui-tel-input';
import { themeColors } from 'src/theme';

interface TextInputProps extends OutlinedTextFieldProps {
  isPassword?: boolean;
  isPhoneNumber?: boolean;
}

const MaterialTextField: React.FC<TextInputProps> = ({
  isPassword = false,
  isPhoneNumber = false,
  ...textFieldProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (value: any) => {
    setPhoneNumber(value);
    textFieldProps.onChange && textFieldProps.onChange(value);
  };

  const commonInputProps = {
    required: true,
    fullWidth: true,
    InputLabelProps: {
      required: false,
      style: { color: themeColors.border.main },
    },
    sx: {
      '& .MuiTextField-root': {
        color: themeColors.info.main,
      },
      '& .MuiInputBase-input': {
        color: themeColors.info.main,
      },
      '& .MuiOutlinedInput-root': {
        background: '#f5f5f7',
        '& fieldset': {
          borderColor: 'transparent',
          color: 'black',
        },
        '&:hover fieldset': { borderColor: 'transparent' },
        '&.Mui-focused fieldset': { borderColor: 'transparent' },
      },
    },
  };

  return (
    <>
      {isPhoneNumber ? (
        <MuiTelInput
          {...textFieldProps}
          {...commonInputProps}
          defaultCountry={'US'}
          inputProps={{ maxLength: 20 }}
          margin="normal"
          color="success"
          InputProps={{ required: true }}
          MenuProps={{
            sx: {
              background: '#f5f5f7',
              '& .MuiButtonBase-root': {
                color: 'black',
                background: '#f5f5f7',
              },
            },
          }}
          onChange={handlePhoneNumberChange}
          value={phoneNumber}
        />
      ) : (
        <TextField
          {...textFieldProps}
          {...commonInputProps}
          type={isPassword && !showPassword ? 'password' : 'text'}
          margin="normal"
          color="success"
          InputProps={{
            ...(isPassword && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    color="info"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }),
            style: { color: 'white', background: '#f5f5f7' },
          }}
        />
      )}
    </>
  );
};

export default MaterialTextField;
