import { FC } from 'react';
import styles from './SubscriptionInfo.module.scss';
import { CATEGORIES } from 'src/const/Categories/Categories';
import { CategoryItem } from 'src/components/common/Category/CategoryItem/CategoryItem';
interface Habit {
  name: string;
  category: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
}

interface SubscriptionInfoProps {
  member_count_max: number;
  price: number;
  habits: Habit[];
  postLenght: number;
}

export const SubscriptionInfo: FC<SubscriptionInfoProps> = ({
  member_count_max = 0,
  price = 0,
  habits = [],
  postLenght,
}) => {
  const uniqueCategories = Array.from(
    new Set(habits.map((habit) => habit.category))
  );

  const getCategoryImage = (category: string) => {
    const categoryData = CATEGORIES.find((cat: any) => cat.name === category);
    return categoryData
      ? categoryData.img
      : CATEGORIES.find((cat: any) => cat.name === 'other')?.img;
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.info}>
          {member_count_max} <span>Members</span>
        </div>
        <div className={styles.info}>
          {postLenght} <span>Posts</span>
        </div>
        <div className={styles.info}>
          ${price} <span>Per month</span>
        </div>
      </div>
      <div className={styles.categories}>
        <div className={styles.categoryList}>
          {uniqueCategories.map((category, index) => (
            <CategoryItem
              key={index}
              item={{ name: category, img: getCategoryImage(category) }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
