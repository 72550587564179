import { ChangeEvent, FC, useState, useCallback, useEffect } from 'react';
import { CommonField } from './CommonField/CommonField';
import { Header } from './Header/Header';
import { UploadVideo } from 'src/components/common/Video/Video';
import styles from './EditCircle.module.scss';
import { DESCRIPTION_FIELD, NAME_FIELD } from './const';
import { Button, Typography } from '@mui/material';
import Habits from 'src/components/common/Habits/Habits';
import { ChooseCategory } from './Category/Category';
import Members from './Members/Members';
import { circleApi } from 'src/api/circles';
import { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import { Subscription } from '../CreateCircle/Subscription/Subscription';
interface FormData {
  tribe_name: string;
  categories: string[];
  video: string;
  description: string;
  member_count_max: number;
  habits: { name: string; category: string }[];
  membership_type: string;
  welcome_video_url: string;
}

export const EditCircle: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<FormData>({
    tribe_name: '',
    categories: [],
    video: '',
    description: '',
    member_count_max: 1,
    habits: [],
    membership_type: 'PAID',
    welcome_video_url: '',
  });

  useEffect(() => {
    if (id) {
      circleApi.getCircle(id).then((res) => {
        setFormData(() => ({
          ...res.data.result,
        }));
      });
    }
  }, [id]);
  console.log(formData, 'formData');
  const [error, setError] = useState<string>('');

  const handleInputChange = useCallback(
    (field: keyof FormData) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: event.target.value,
        }));
      },
    []
  );

  const handleSelectChange = useCallback(
    (field: keyof FormData) => (event: SelectChangeEvent) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(event.target.value),
      }));
    },
    []
  );

  const handleCategoryClick = useCallback((category: string) => {
    setFormData((prevData) => {
      const isSelected = prevData.categories.includes(category);
      let updatedCategories: string[] = [];

      if (isSelected) {
        updatedCategories = prevData.categories.filter(
          (cat) => cat !== category
        );
      } else if (prevData.categories.length < 4) {
        updatedCategories = [...prevData.categories, category];
      } else {
        updatedCategories = prevData.categories;
      }

      return {
        ...prevData,
        categories: updatedCategories,
      };
    });
  }, []);

  const handleSubscriptionChange = useCallback((type: string) => {
    setFormData((prevData) => ({
      ...prevData,
      membership_type: type,
    }));
  }, []);

  const handleHabitsChange = useCallback(
    (newHabits: { name: string; category: string }[]) => {
      setFormData((prevData) => ({
        ...prevData,
        habits: newHabits,
      }));
    },
    []
  );

  const validateForm = useCallback((): boolean => {
    const {
      tribe_name,
      categories,
      video,
      description,
      member_count_max,
      habits,
    } = formData;

    if (
      !tribe_name ||
      categories.length === 0 ||
      !video ||
      !description ||
      member_count_max === 0 ||
      habits.length === 0
    ) {
      setError('All fields are required');
      return false;
    }

    setError('');
    return true;
  }, [formData]);

  const createCircle = useCallback(() => {
    if (!validateForm()) return;

    circleApi.createCircle(formData).then((res) => {
      console.log('success');
    });
  }, [formData, validateForm]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header />
        <CommonField
          title={NAME_FIELD.title}
          description={NAME_FIELD.description}
          placeholder={NAME_FIELD.placeholder}
          charactersLength={NAME_FIELD.charactersLength}
          anhor={'info-circle-name'}
          value={formData.tribe_name}
          setValue={handleInputChange('tribe_name')}
        />
        <ChooseCategory
          selectedCategory={formData.categories}
          handleCategoryClick={handleCategoryClick}
        />
        <UploadVideo
          defaultVideo={formData.welcome_video_url}
          onUpdate={(fileId) => {
            console.log('Video uploaded with ID:', fileId);
          }}
          helperText="Upload a video (max 60 seconds)"
        />

        <CommonField
          type="textarea"
          title={DESCRIPTION_FIELD.title}
          description={DESCRIPTION_FIELD.description}
          placeholder={DESCRIPTION_FIELD.placeholder}
          charactersLength={DESCRIPTION_FIELD.charactersLength}
          anhor={'info-description'}
          value={formData.description}
          setValue={handleInputChange('description')}
        />
        <Members
          value={formData.member_count_max}
          setValue={handleSelectChange('member_count_max')}
        />
        <Habits
          description="“Habits” describe the daily activities and general mindset that lead to success for your Circle’s purpose."
          onHabitsChange={handleHabitsChange}
          habitsArr={formData.habits}
        />
        <Subscription
          subscriptionType={formData.membership_type}
          onSubscriptionChange={handleSubscriptionChange}
          membersCount={formData.member_count_max}
        />
        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{ mb: 1, mt: 1, textAlign: 'center' }}
          >
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
          size="large"
          onClick={createCircle}
        >
          Edit Circle
        </Button>
      </div>
    </div>
  );
};
