import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '5rape27ni061fp8jjjk2hndhj1',
      userPoolId: 'us-east-2_nxU1rv6ma',
    },
  },
});

// us-east-2_nxU1rv6ma prod
// us-west-1_RTwDQf8la stage
//cliend id prod 5rape27ni061fp8jjjk2hndhj1
//cliend id stage 	4cc5nac7nq5r480ho1n8viv73b
