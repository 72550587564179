import api from './baseApi';

export const postsApi = {
  getPostsForCircle: (id: string) => {
    return api.get(`posts/?tribe_id=${id}`);
  },
  getSelectedPost: (id: string) => {
    return api.get(`posts/${id}`);
  },

  getCommentsForSelectedPost: (postId: string) => {
    return api.get(`posts/${postId}/comments`);
  },
};
