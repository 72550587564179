import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import styles from './UserInfo.module.scss';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import arrow from 'src/assets/images/arrow/arrow-icon.png';
import { FC } from 'react';
import { TribeDetail } from 'src/pages/Home/const';

export const UserInfo: FC<TribeDetail> = ({
  leader_name,
  leader_avatar_url,
  tribe_name,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar src={leader_avatar_url} sx={{ width: 85, height: 85 }}>
          {leader_name.charAt(0)}
        </Avatar>
      </div>
      <div className={styles.info}>
        <div className={styles.data}>
          <div className={styles.title}>{tribe_name}</div>
          <div className={styles.bio}>{leader_name}</div>
        </div>
        <Button
          className={styles['btn']}
          type="submit"
          sx={{ mt: 1, mb: 1, textTransform: 'none', fontSize: '14px' }}
          size="medium"
          fullWidth
        >
          Members
        </Button>
      </div>
      <div className={styles.iconsWrapper}>
        <img src={arrow} alt="" className={styles.img} />
        <MoreVertRoundedIcon sx={{ color: 'black' }} />
      </div>
    </div>
  );
};
