import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import styles from './UserInfo.module.scss';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import Popover from '@mui/material/Popover';
import { FC, useState } from 'react';
import { UserInfoProps } from '../const';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { Link } from 'react-router-dom';

export const UserInfo: FC<UserInfoProps> = ({ result }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popper' : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar
          src={result ? result.avatar_url : ''}
          sx={{ width: 85, height: 85 }}
        >
          {result.full_name.charAt(0)}
        </Avatar>
      </div>
      <div className={styles.info}>
        <div className={styles.data}>
          <div className={styles.title}>{result.full_name}</div>
          {/* <div className={styles.bio}>{result.bio}</div> */}
        </div>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1, mb: 2, textTransform: 'none', fontSize: '14px' }}
          size="medium"
          className={styles.btn}
          onClick={() => navigate(PathRoutes.EditProfileRoute)}
        >
          Edit Profile
        </Button>
      </div>
      <div className={styles.iconsWrapper}>
        {/* <img src={arrow} alt="" className={styles.img} /> */}
        <div onClick={handleClick}>
          <MoreVertRoundedIcon sx={{ color: 'black' }} />
        </div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <div className={styles.popover}>
            <Link to={PathRoutes.CreateCircleRoute}> Create circle</Link>
          </div>
        </Popover>
      </div>
    </div>
  );
};
