import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { circleApi } from 'src/api/circles';
import { postsApi } from 'src/api/postst';
import { TribeDetail } from '../Home/const';
import { CircleDataItem } from './conts';
import { UserInfo } from './UserInfo/UserInfo';
import { SubscriptionInfo } from './SubscriptionInfo/SubscriptionInfo';
import TabsAuction from './Tabs/Tabs';
import styles from './UserCircle.module.scss';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';

export const UserCircle = () => {
  const [circleData, setCircleData] = useState<TribeDetail | null>(null);
  const [postData, setPostData] = useState<CircleDataItem[] | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setCircleData(null);
    setPostData(null);

    if (id) {
      circleApi.getCircle(id).then((res) => {
        setCircleData(res.data.result);
      });

      postsApi.getPostsForCircle(id).then((res) => {
        const uniqueData = res.data.result.filter(
          (item: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t._id === item._id)
        );
        setPostData(uniqueData);
      });
    }
  }, [id]);
  return (
    <div className={styles.container}>
      {circleData && postData ? (
        <>
          <UserInfo {...circleData} />
          <SubscriptionInfo
            member_count_max={circleData?.member_count_max || 0}
            price={circleData?.price || 0}
            habits={circleData?.habits || []}
            postLenght={postData.length}
          />
          <TabsAuction
            postData={postData}
            description={circleData?.description}
          />
        </>
      ) : (
        <div>
          <MaterialCircularPogress />
        </div>
      )}
    </div>
  );
};
