import { FC } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button } from '@mui/material';
import { useUserLoginLogout } from 'src/hooks/UserLoginLogout/UserLoginLogout';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import MaterialTextField from 'src/components/material/textField';
import { fetchUser } from 'src/redux/slices/userSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import styles from './LoginType.module.scss';

const schema = z.object({
  phoneNumber: z.string().min(10, 'Phone number must be at least 10 digits'),
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/[0-9]/, 'Password must contain at least one number'),
});

interface LoginTypeProps {
  setStep: (step: number) => void;
}

interface IFormInput {
  phoneNumber: string;
  password: string;
}

export const LoginType: FC<LoginTypeProps> = ({ setStep }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { login, error } = useUserLoginLogout();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const result = await login({
      username: data.phoneNumber,
      password: data.password,
    });
    if (result?.isSignedIn) {
      dispatch(fetchUser());
      navigate(PathRoutes.HomeRoute);
    } else {
      reset({
        phoneNumber: data.phoneNumber,
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.title}>Log in</div>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <MaterialTextField
                isPhoneNumber
                label="Phone Number"
                variant="outlined"
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber ? errors.phoneNumber.message : ''
                }
                {...field}
                color="primary"
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <MaterialTextField
                label="Password"
                variant="outlined"
                isPassword
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : error}
                {...field}
                color="success"
              />
            )}
          />
          <div className={styles.forgetPassword}>
            <div className={styles.forgetPassword} onClick={() => setStep(3)}>
              Forgot your password?
            </div>
          </div>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
            size="large"
          >
            Log in
          </Button>
        </form>
        <div className={styles.border} />
        <div className={styles.footer}>
          Don't have an account ?{' '}
          <div className={styles.link} onClick={() => setStep(2)}>
            Sign up
          </div>
        </div>
      </div>
    </div>
  );
};
