import { useEffect, useState } from 'react';
import { usersApi } from 'src/api/user';
import { TribeDetail } from 'src/pages/Home/const';
import { Circleitem } from './CircleItem/CircleItem';
import styles from './Circles.module.scss';

export const Circles = () => {
  const [circles, setCircles] = useState<TribeDetail[] | null>(null);

  useEffect(() => {
    usersApi.userMe().then((res: any) => {
      setCircles(res.data.tribe_details);
    });
  }, []);

  const approvedCircles = circles
    ? circles.filter((item) => item.status === 'APPROVED')
    : [];

  return (
    <div>
      {approvedCircles.length > 0 ? (
        <>
          <div className={styles.title}>Circles</div>
          <div
            className={`${styles.list} ${approvedCircles.length > 5 ? styles.scrollable : ''}`}
          >
            {approvedCircles.map((item) => (
              <Circleitem item={item} key={item._id} />
            ))}
          </div>
        </>
      ) : (
        <div>You don't have approved circles</div>
      )}
    </div>
  );
};
