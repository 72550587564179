import { FC, useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { VideoItemProps } from 'src/pages/UserCircle/conts';
import styles from './VideoItem.module.scss';
import { SelectedVideoModal } from '../SelectedVideoModal/SelectedVideoModal';

export const VideoItem: FC<VideoItemProps> = ({ item }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    let hls: Hls | undefined;

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(item.video_url);
      hls.attachMedia(videoRef.current!);
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = item.video_url;
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [item.video_url]);

  const handleMouseOver = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseOut = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleClick = () => {
    setIsOpenModal(true);
  };

  return (
    <div className={styles.videoContainer} onClick={handleClick}>
      <video
        ref={videoRef}
        controls
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{
          width: '100%',
          height: '500px',
          objectFit: 'cover',
          background: 'black',
          cursor: 'pointer',
        }}
      >
        Your browser does not support the video tag.
      </video>
      <SelectedVideoModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        videoId={item._id}
        videoUrl={item.video_url}
      />
    </div>
  );
};
