import { ChangeEvent, FC, useState, useCallback, useEffect } from 'react';
import { CommonField } from './CommonField/CommonField';
import { Header } from './Header/Header';
import { UploadVideo } from 'src/components/common/Video/Video';
import styles from './CreateCircle.module.scss';
import { DESCRIPTION_FIELD, NAME_FIELD } from './const';
import { Button, Typography } from '@mui/material';
import Habits from 'src/components/common/Habits/Habits';
import { Subscription } from './Subscription/Subscription';
import { ChooseCategory } from './Category/Category';
import Members from './Members/Members';
import { circleApi } from 'src/api/circles';
import { SelectChangeEvent } from '@mui/material/Select';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { getCurrentUserId } from 'src/redux/slices/userSlice';
import { usersApi } from 'src/api/user';
interface FormData {
  leader_name: string;
  leader_avatar_url: string;
  tribe_name: string;
  leader_id: string;
  categories: string[];
  welcome_video_id: string;
  description: string;
  member_count_max: number;
  habits: { name: string; category: string }[];
  membership_type: string;
  price: number;
}

export const CreateCircle: FC = () => {
  const userId = useAppSelector(getCurrentUserId);
  const [formData, setFormData] = useState<FormData>({
    leader_name: '', // Initialize with an empty string
    leader_avatar_url: '', // Initialize with an empty string
    tribe_name: '',
    categories: [],
    welcome_video_id: '',
    description: '',
    member_count_max: 1,
    habits: [],
    membership_type: 'FREE',
    price: 0,
    leader_id: userId || '',
  });

  useEffect(() => {
    usersApi.userMe().then((res) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        leader_name: res.data.result.full_name,
        leader_avatar_url: res.data.result.avatar_url,
      }));
    });
  }, []);

  const [error, setError] = useState<string>('');

  const handleInputChange = useCallback(
    (field: keyof FormData) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: event.target.value,
        }));
      },
    []
  );

  const handleSelectChange = useCallback(
    (field: keyof FormData) => (event: SelectChangeEvent) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(event.target.value),
      }));
    },
    []
  );

  const handleCategoryClick = useCallback((category: string) => {
    setFormData((prevData) => {
      const isSelected = prevData.categories.includes(category);
      let updatedCategories: string[] = [];

      if (isSelected) {
        updatedCategories = prevData.categories.filter(
          (cat) => cat !== category
        );
      } else if (prevData.categories.length < 4) {
        updatedCategories = [...prevData.categories, category];
      } else {
        updatedCategories = prevData.categories;
      }

      return {
        ...prevData,
        categories: updatedCategories,
      };
    });
  }, []);

  const handleSubscriptionChange = useCallback((type: string) => {
    setFormData((prevData) => ({
      ...prevData,
      membership_type: type,
    }));
  }, []);

  const handleHabitsChange = useCallback(
    (newHabits: { id?: string; name: string; category: string }[]) => {
      const habitsWithoutId = newHabits.map(({ name, category }) => ({
        name,
        category,
      }));
      setFormData((prevData) => ({
        ...prevData,
        habits: habitsWithoutId,
      }));
    },
    []
  );

  const validateForm = useCallback((): boolean => {
    const {
      tribe_name,
      categories,
      welcome_video_id,
      description,
      member_count_max,
      habits,
    } = formData;

    if (
      !tribe_name ||
      categories.length === 0 ||
      !welcome_video_id ||
      !description ||
      member_count_max === 0 ||
      habits.length === 0
    ) {
      setError('All fields are required');
      return false;
    }

    setError('');
    return true;
  }, [formData]);

  const createCircle = useCallback(() => {
    if (!validateForm()) return;
    circleApi.createCircle(formData).then((res) => {
      window.location.href = 'https://buy.stripe.com/test_bIYdRU8f47sT2EoeUU';
    });
  }, [formData, validateForm]);

  const handleImageUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setFormData({ ...formData, welcome_video_id: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header />
        <CommonField
          title={NAME_FIELD.title}
          description={NAME_FIELD.description}
          placeholder={NAME_FIELD.placeholder}
          charactersLength={NAME_FIELD.charactersLength}
          anhor={'info-circle-name'}
          value={formData.tribe_name}
          setValue={handleInputChange('tribe_name')}
        />
        <ChooseCategory
          selectedCategory={formData.categories}
          handleCategoryClick={handleCategoryClick}
        />
        <UploadVideo
          defaultVideo={''}
          onUpdate={handleImageUpdate}
          helperText="Upload a video (max 60 seconds)"
        />
        <CommonField
          type="textarea"
          title={DESCRIPTION_FIELD.title}
          description={DESCRIPTION_FIELD.description}
          placeholder={DESCRIPTION_FIELD.placeholder}
          charactersLength={DESCRIPTION_FIELD.charactersLength}
          anhor={'info-description'}
          value={formData.description}
          setValue={handleInputChange('description')}
        />
        <Habits
          description="“Habits” describe the daily activities and general mindset that lead to success for your Circle’s purpose."
          onHabitsChange={handleHabitsChange}
        />
        <Members
          value={formData.member_count_max}
          setValue={handleSelectChange('member_count_max')}
        />
        <Subscription
          subscriptionType={formData.membership_type}
          onSubscriptionChange={handleSubscriptionChange}
          membersCount={formData.member_count_max}
        />
        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{ mb: 1, mt: 1, textAlign: 'center' }}
          >
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
          size="large"
          onClick={createCircle}
        >
          Create Circle
        </Button>
      </div>
    </div>
  );
};
