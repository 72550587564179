import styles from './Home.module.scss';
import { UserInfo } from './UserInfo/UserInfo';
import { SubscriptionInfo } from './SubscriptionInfo/SubscriptionInfo';
// import TabsAuction from './Tabs/Tabs';
import { useEffect, useState } from 'react';
import { usersApi } from 'src/api/user';
import { UserInfoProps } from './const';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';

export const Home = () => {
  const [userData, setUserData] = useState<UserInfoProps | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    usersApi
      .userMe()
      .then((res: any) => {
        setUserData(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <MaterialCircularPogress />
      ) : userData ? (
        <>
          <UserInfo {...userData} />
          <SubscriptionInfo {...userData} />
          {/* <TabsAuction /> */}
        </>
      ) : null}
    </div>
  );
};
