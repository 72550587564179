import { FC } from 'react';
import { TribeDetail } from 'src/pages/Home/const';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import styles from './CircleItem.module.scss';

export const Circleitem: FC<{ item: TribeDetail }> = ({ item }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (item.role === 'LEADER') {
      navigate(`/edit-circle/${item._id}`);
    }
  };

  const getTruncatedTribeName = (name: string) => {
    if (name.length > 15) {
      return `${name.slice(0, 13)}...`;
    }
    return name;
  };

  if (!item.tribe_name) {
    return null;
  }

  return (
    <div
      onClick={handleClick}
      className={styles.container}
      style={{ cursor: item.role === 'LEADER' ? 'pointer' : 'default' }}
    >
      <Avatar
        src={item ? item.tribe_avatar_url : ''}
        sx={{ width: 45, height: 45 }}
      >
        {item.tribe_name ? item.tribe_name.charAt(0) : 'C'}
      </Avatar>
      <div className={styles.textWrapper}>
        <div className={styles.tribeName}>
          {getTruncatedTribeName(item.tribe_name)}
        </div>
        <div className={styles.leaderName}>{item.leader_name}</div>
      </div>
    </div>
  );
};
