import { useEffect, useState } from 'react';
import { auctionApi } from 'src/api/auctionApi';
import { AuctionProps } from './conts';
import { UserInfo } from './UserInfo/UserInfo';
import { SubscriptionInfo } from './SubscriptionInfo/SubscriptionInfo';
import { TabsAuction } from './Tabs/Tabs';
import styles from './Auction.module.scss';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';

export const Auction = () => {
  const [auctionData, setAuctionData] = useState<AuctionProps | null>(null);

  useEffect(() => {
    setAuctionData(null);

    auctionApi.getAuctionInfo().then((res: any) => {
      console.log(res.data.result);
      setAuctionData(res.data.result);
    });
  }, []);

  return (
    <div className={styles.container}>
      {auctionData ? (
        <>
          <UserInfo {...auctionData} />
          <SubscriptionInfo {...auctionData} />
          <TabsAuction {...auctionData} />
        </>
      ) : (
        <div>
          <MaterialCircularPogress />
        </div>
      )}
    </div>
  );
};
